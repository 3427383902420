<template>
  <div>
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'NodeprojectPressandnews',
  components: {  },
  directives: {  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style  scoped>

</style>